<script>
import {
  SendIcon,
  MailIcon,
  LinkIcon,
  PhoneCallIcon,
  ClockIcon,
  MapPinIcon,
  InstagramIcon,
  TwitterIcon
} from "vue-feather-icons";
export default {
  components: {
    SendIcon,
    MailIcon,
    LinkIcon,
    PhoneCallIcon,
    ClockIcon,
    MapPinIcon,
    InstagramIcon,
    TwitterIcon
  }
};
</script>
<template>
  <!-- Contact Us Start -->
  <section class="section" id="contact">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="text-center mb-5">
            <h3 class="title mb-3">Contáctanos</h3>
            <p class="text-secondary font-size-15">
              Si aun tienes dudas o quieres contarnos algo, nustro equipo estará para atenderte.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- <div class="col-lg-7">
          <div class="custom-form mb-5 mb-lg-0">
            <div id="message"></div>
            <form name="contact-form" id="contact-form">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Nombre*</label>
                    <input id="name" type="text" class="form-control" placeholder="Tu nombre y apellido..." />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="email">Email*</label>
                    <input id="email" type="email" class="form-control" placeholder="Ej: tusuario@email.com..." />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label for="comments">Mensaje*</label>
                    <textarea
                      id="comments"
                      rows="4"
                      class="form-control"
                      placeholder="Tu mensaje..."
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <button type="button" class="btn btn-primary">
                    Enviar Mensaje
                    <send-icon class="icon-size-15 ml-2 icon"></send-icon>
                  </button>
                  <div id="simple-msg"></div>
                </div>
              </div>
            </form>
          </div>
        </div> -->
       <!--  <div class="col-lg-5">
          <div class="contact-detail text-muted ml-lg-5">
            <p class>
              <mail-icon class="icon-xs icon mr-1"></mail-icon>:
              <span>contacto@condominiosvenezuela.com</span>
            </p>
            <p class>
              <LinkIcon class="icon-xs icon mr-1"></LinkIcon>:
              <a href="http://www.condominiosvenezuela.com" target="_blank"><span>www.condominiosvenezuela.com</span></a>
            </p>
            <p class>
              <PhoneCallIcon class="icon-xs icon mr-1"></PhoneCallIcon>:
              <span>(+56) 212 345 6789</span>
            </p>
            <p class>
              <InstagramIcon class="icon-xs icon mr-1"></InstagramIcon>:
              <a href="https://www.instagram.com/condominiosvenezuela" target="_blank"><span>@condominiosvenezuela</span></a>
            </p>
            <p class>
              <TwitterIcon class="icon-xs icon mr-1"></TwitterIcon>:
              <a href="https://twitter.com/condominiosvenezuela" target="_blank"><span>@condominiosvenezuela</span></a>
            </p>
          </div>
        </div> -->
        <div class="col-lg-6">
          <div class="pricing-box rounded text-center p-4" style="height: 500px;">
            <div class="pricing-icon-bg my-4">
              <i class="mdi mdi-lifebuoy h1"></i>
            </div>
            <h4 class="title mb-4">Atención 24/7 a través de nuestros correos y centro de soporte</h4>
            <!-- <h1 class="font-weight-bold mb-0">
              <b>
                <sup class="h4 mr-2 font-weight-bold">$</sup>20 - <sup class="h4 mr-2 font-weight-bold">$</sup>99
              </b>
            </h1> -->
            <p class="text-muted font-weight-semibold font-size-14 wc-subtitle"> 
                <a href="mailto:condominiosvenezuela@gmail.com">
                    <span class>
                        <mail-icon class="icon-xs icon mr-1"></mail-icon>
                        <span>condominiosvenezuela@gmail.com</span>
                    </span>
                </a>
                <br>
                <a href="mailto:condominiosvenezuela@gmail.com">
                    <span class>
                        <mail-icon class="icon-xs icon mr-1"></mail-icon>
                        <span>soporte@condominiosvenezuela.com</span>
                    </span>
                </a>
                <!-- <a href="mailto:soporte@condominiosvenezuela.com">soporte@condominiosvenezuela.com</a> -->
            </p>
            <!-- <ul class="list-unstyled pricing-item mb-3">
              <li class="text-muted">Rendimiento: 3% x día</li>
            </ul> -->
            <p class="text-muted font-weight-semibold mb-5 font-size-14 wc-subtitle" style="margin-bottom: 0.5rem; margin-top: 2rem;">
                Tambièn puedes generar un ticket y/o solicitar cotización en: <a href="http://soporte.condominiosvenezuela.com/?v=submit_ticket&action=displayForm&departament=2" target="_blank">soporte.condominiosvenezuela.com</a>
            </p>
            <!-- <ul class="text-left mb-3">
              <li class="text-muted">Nivel 1: 7%</li>
              <li class="text-muted">Nivel 2: 5%</li>
              <li class="text-muted">Nivel 3: 3%</li>
            </ul> -->
            <!-- <a href="javascript: void(0);" class="btn btn-outline-primary pr-btn">Buy Now</a> -->
            <div class="pt-5">
              <div class="hero-bottom-img">
                <img
                  src="@/assets/images/pricing-bottom-bg.png"
                  alt
                  class="img-fluid d-block mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="pricing-box rounded text-center p-4" style="height: 500px;">
            <div class="pricing-icon-bg my-4">
              <i class="mdi mdi-web h1"></i>
            </div>
            <h4 class="title mb-4">www.condominiosvenezuela.com</h4>
            <!-- <h1 class="font-weight-bold mb-0">
              <b>
                <sup class="h4 mr-2 font-weight-bold">$</sup>20 - <sup class="h4 mr-2 font-weight-bold">$</sup>99
              </b>
            </h1> -->
            <p class="text-muted font-weight-semibold font-size-14 wc-subtitle">Es una aplicación desarrollada por<br>
Suministros y Soluciones Informáticas de Venezuela C. A.<br>
RIF: J-40158225-7</p>
            <!-- <ul class="list-unstyled pricing-item mb-3">
              <li class="text-muted">Rendimiento: 3% x día</li>
            </ul> -->
            <p class="text-muted font-weight-semibold mb-5 font-size-14 wc-subtitle" style="margin-bottom: 0.5rem;">
                Atención Telefónica: <b class="text-primary-dark">+58 426 4825947</b><br>
                de <b class="text-primary-dark">Lunes a Viernes</b> en Horario de <br><b class="text-primary-dark">8:00 am - 12:00 pm, 2:00 pm - 6:00 pm</b>
            </p>
            <!-- <ul class="text-left mb-3">
              <li class="text-muted">Nivel 1: 7%</li>
              <li class="text-muted">Nivel 2: 5%</li>
              <li class="text-muted">Nivel 3: 3%</li>
            </ul> -->
            <!-- <a href="javascript: void(0);" class="btn btn-outline-primary pr-btn">Buy Now</a> -->
            <div class="pt-5">
              <div class="hero-bottom-img">
                <img
                  src="@/assets/images/pricing-bottom-bg.png"
                  alt
                  class="img-fluid d-block mx-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Contact Us End -->
</template>